<template>
	<div>
		<CRow>
			<CCol class="text-right">
				<router-link
					:to="{ name: 'FlashSaleCreate' }"
					class="btn btn-secondary mb-3"
				>
					<CIcon class="mr-2" name="cil-plus" />
					Create flash sale
				</router-link>
			</CCol>
		</CRow>

		<CRow>
			<CCol class="d-flex align-items-start">
				<form
					class="search-form flex-fill"
					@submit.prevent="handleSearch"
				>
					<CInput
						v-model.trim="queryParams.q"
						data-test-id="search-input"
						placeholder="Search by flash sale name or ID"
					>
						<template #prepend-content>
							<CIcon
								name="cil-magnifying-glass"
								data-test-id="search-button"
							/>
						</template>
					</CInput>
				</form>
				<BaseFilterButton
					class="ml-3"
					data-test-id="filter-button"
					:is-open="isShowFilter"
					:has-filter="hasFilter"
					@onClick="toggleFilter"
				/>
			</CCol>
		</CRow>

		<CRow>
			<CCol>
				<transition name="fade">
					<div
						v-if="isShowFilter"
						class="filter-box rounded-sm pt-3 px-3 mb-3"
					>
						<CRow>
							<CCol md="3">
								<BaseDropDown
									v-model="filter.status"
									:searchable="false"
									:options="FLASHSALE_STATUS_OPTIONS"
									:allow-empty="false"
									class="select-custom"
									label="name"
									track-by="value"
									label-drop-down="Flash sale status"
									@input="handleFlashSaleStatus"
								/>
							</CCol>
							<CCol md="3">
								<BaseInputDate
									:value="startDate"
									:max-date="endDate"
									label="Start date"
									placeholder="All date"
									@input="handleFilterFlashSaleStartDate"
								>
									<template #append-content>
										<CIcon class="cil-calendar" name="cil-calendar" />
									</template>
								</BaseInputDate>
							</CCol>
							<CCol md="3">
								<BaseInputDate
									:value="endDate"
									:min-date="startDate"
									label="End date"
									placeholder="All date"
									@input="handleFilterFlashSaleEndDate"
								>
									<template #append-content>
										<CIcon class="cil-calendar" name="cil-calendar" />
									</template>
								</BaseInputDate>
							</CCol>
							<CCol md="3">
								<BaseDropDown
									v-model="filter.period"
									:searchable="false"
									:options="FLASHSALE_PERIODS_OPTIONS"
									:allow-empty="false"
									class="select-custom"
									label="name"
									track-by="value"
									label-drop-down="Period status"
									@input="handleFlashSalePeriod"
								/>
							</CCol>
						</CRow>
					</div>
				</transition>
			</CCol>
		</CRow>

		<CRow class="mb-4">
			<CCol>
				<BaseSearchFormFooter
					:count="list.meta.total"
					append-text="list(s) found"
					@onReset="resetFilter"
				/>
			</CCol>
		</CRow>

		<CRow>
			<CCol>
				<BaseTable
					:is-loading="isLoading"
					:pagination="{
						pages: list.meta.lastPage,
						activePage: list.meta.currentPage,
					}"
					:fields="FLASHSALE_TABLE_FIELDS"
					:items="list.data"
					clickable-rows
					striped
					link-to="FlashSaleEdit"
					vertical-align="top"
					class="table-custom"
					@onPaginationClick="handlePageChange"
				>
					<template #no-items-view>
						<div class="empty-table-element">
							<p class="subtitle">
								{{
									$t('global.searchNotFound', {
										field: 'flash sales',
									})
								}}
							</p>
						</div>
					</template>
					<template #status="{item}">
						<CBadge class="badge-status" :color="FLASHSALE_COLOR.STATUS[item]">
							{{ FLASHSALE_TITLE.STATUS[item] }}
						</CBadge>
					</template>
					<template #period="{item}">
						<CBadge class="badge-status" :color="item.color">
							{{ item.title }}
						</CBadge>
					</template>
					<template #duplicateId="{item}">
						<CLink
							variant="ghost"
							color="secondary"
							:to="{ name: 'FlashSaleCreate', query: { ref: item } }"
						>
							Duplicate
						</CLink>
					</template>
				</BaseTable>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import BaseInputDate from '@/components/BaseInputDate.vue';
import {
	FLASHSALE_TABLE_FIELDS,
	FLASHSALE_STATUS_OPTIONS,
	FLASHSALE_PERIODS_OPTIONS,
	FLASHSALE_TITLE,
	FLASHSALE_COLOR,
} from '../enums/flashSales';
import {
	cleanObject,
	randomSearchString,
	hasSearchFilter,
	dateFormat,
	getBooleanQueryParameter,
	cloneDeep,
} from '../assets/js/helpers';

export default {
	name: 'FlashSales',
	components: {
		BaseInputDate,
	},
	data() {
		return {
			FLASHSALE_TABLE_FIELDS,
			FLASHSALE_STATUS_OPTIONS,
			FLASHSALE_PERIODS_OPTIONS,
			FLASHSALE_TITLE,
			FLASHSALE_COLOR,
			queryParams: {
				q: this.$route.query.q || null,
				page: Number(this.$route.query.page) || null,
				is_active: getBooleanQueryParameter(this.$route.query.is_active),
				period_status: this.$route.query.period_status || null,
				start_at: this.$route.query.start_at || null,
				end_at: this.$route.query.end_at || null,
				r: randomSearchString(),
			},
			filter: {
				status: this.getSelectedValue(this.$route.query.is_active, FLASHSALE_STATUS_OPTIONS),
				period: this.getSelectedValue(this.$route.query.period_status, FLASHSALE_PERIODS_OPTIONS),
			},
			isShowFilter: false,
		};
	},
	computed: {
		...mapState('flashSales', {
			list: 'list',
		}),
		isLoading() {
			return this.list.isLoading;
		},
		hasFilter() {
			return hasSearchFilter(this.queryParams);
		},
		startDate() {
			if (!this.queryParams.start_at) {
				return null;
			}

			return new Date(this.queryParams.start_at);
		},
		endDate() {
			if (!this.queryParams.end_at) {
				return null;
			}

			return new Date(this.queryParams.end_at);
		},
	},
	async created() {
		this.isShowFilter = this.hasFilter;
		await this.getFlashSales(this.queryParams);
	},
	methods: {
		...mapActions({
			getFlashSales: 'flashSales/getFlashSales',
		}),

		toggleFilter() {
			this.isShowFilter = !this.isShowFilter;
		},

		getSelectedValue(selectedValue = null, options = {}) {
			const statusOptionValues = Object.values(options);
			const findStatus = ({ value }) => value === selectedValue;
			return statusOptionValues.find(findStatus) || {
				name: null,
				value: null,
			};
		},

		updateUrlParams() {
			const query = cleanObject(cloneDeep(this.queryParams));
			this.$router.push({ query }).catch(() => {});
		},

		handlePageChange(page) {
			this.queryParams.page = page;
			this.updateUrlParams();
		},

		handleSearch() {
			this.queryParams.page = null;
			this.updateUrlParams();
		},

		handleFlashSaleStatus({ value }) {
			this.queryParams.page = null;
			this.queryParams.is_active = value;
			this.updateUrlParams();
		},

		handleFilterFlashSaleStartDate(value) {
			this.queryParams.page = null;
			this.queryParams.start_at = dateFormat(value);
			this.updateUrlParams();
		},

		handleFilterFlashSaleEndDate(value) {
			this.queryParams.page = null;
			this.queryParams.end_at = dateFormat(value);
			this.updateUrlParams();
		},

		handleFlashSalePeriod(event) {
			this.queryParams.page = null;
			this.queryParams.period_status = event.value;
			this.updateUrlParams();
		},

		resetFilter() {
			this.queryParams = {
				q: null,
				page: null,
				is_active: null,
				period_status: null,
				start_at: null,
				end_at: null,
				r: null,
			};

			this.updateUrlParams();
		},
	},
};
</script>

<style lang="scss" scoped>
	// Set placeholder of filter by date range
	::v-deep .form-control-date {
		input[type="input"] {
			@include placeholder-black;
		}
	}

	.badge-status {
		min-width: rem(65);
	}

	.filter-box {
		background-color: $color-gray-100;
	}
</style>
